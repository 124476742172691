










import { defineComponent } from '@vue/composition-api'
import TitleTooltip from '@/components/TitleTooltip.vue'

export default defineComponent({
    props: {
        name: String
    },
    components: {
        TitleTooltip
    },
    setup() {}
})
