










































































import { defineComponent, reactive, ref } from '@vue/composition-api'
import LayoutHeader from '@/components/LayoutHeader.vue'
import InputBox from '@/components/InputBox.vue'
import { Pagination, Table, TableColumn } from 'element-ui'
import Avatar from '@/components/Avatar.vue'
import { showError } from '@/utils/common'
import { enableUserApi, disableUserApi, getMemberListApi } from '@/api/user'
import { UserRole, UserStatus, UserSourceName } from '@/api/user/model'
import { UserRoleName } from '@/api/user/model'
import _ from 'lodash'
import { useUserStore } from '@/pinia/modules/user'
import { storeToRefs } from 'pinia'
export default defineComponent({
    components: {
        LayoutHeader,
        InputBox,
        Avatar,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Pagination.name]: Pagination
    },
    setup(props, { root }) {
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)
        const listInfo = reactive({
            searchWords: '',
            loading: false,
            page: 1,
            size: 10,
            total: 0,
            init: () => {
                memberList.value = []
                listInfo.page = 1
                getList()
            },
            onLoad: () => getList()
        })


        const onSearchWordsInput = _.debounce(listInfo.init, 1000)

        const memberList = ref([])

        const getList = async () => {
            listInfo.loading = true
            try {
                const filters: any[] = [['nickname', 'like', listInfo.searchWords], ['or'], ['email', 'like', listInfo.searchWords]]
                const { items, total } = await getMemberListApi({
                    page: listInfo.page - 1,
                    size: listInfo.size,
                    filters: JSON.stringify(filters)
                })
                listInfo.total = total
                memberList.value = items
            } catch (error) {
                showError(error)
            }
            listInfo.loading = false
        }

        getList()

        const handleRole = (scope: any) => {
            if (userInfo.value.role === UserRole.Super) {
                return scope.row.id != userInfo.value.id
            } else if (userInfo.value.role === UserRole.Admin) {
                if ([UserRole.Super, UserRole.Admin].includes(scope.row.role)) {
                    return false
                } else {
                    return scope.row.id != userInfo.value.id
                }
            }
            return false
        }

        const onToggleMemberStatus = async (scope: any) => {
            try {
                if (scope.row.status === '-1') {
                    await root.$msgbox({
                        showCancelButton: true,
                        dangerouslyUseHTMLString: true,
                        message: <string>root.$i18n.t('members.enableMessage')
                    })
                    await enableUserApi({
                        id: scope.row.id
                    })
                } else {
                    await root.$msgbox({
                        showCancelButton: true,
                        dangerouslyUseHTMLString: true,
                        message: <string>root.$i18n.t('members.disableMessage')
                    })
                    await disableUserApi({
                        id: scope.row.id
                    })
                }
                getList()
                root.$message.success(root.$t('success').toString())
            } catch (error) {
                if (error != 'cancel') {
                    showError(error)
                }
            }
        }

        return {
            UserRole,
            UserStatus,
            onSearchWordsInput,
            UserRoleName,
            UserSourceName,
            listInfo,
            memberList,
            onToggleMemberStatus,
            handleRole,
        }
    }
})
