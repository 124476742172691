


















import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
    props: {
        title: String,
        lockHint: String,
        width: {
            type: Number,
            default: 400
        }
    },
    setup(props) {
        const contentRef = ref()
        const tooltipDisabled = ref()
        const visibilityChange = (event: any) => {
            const ev = event.target
            const ev_width = ev.offsetWidth
            tooltipDisabled.value = !Boolean(props.width <= ev_width)
        }
        return {
            contentRef,
            tooltipDisabled,
            visibilityChange
        }
    }
})
